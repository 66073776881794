import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Button, styled } from '@mui/material'
import { isEmpty } from 'lodash'
import { Home, LockReset } from '@mui/icons-material'

import { useAppContext } from '../contexts/appContext'
import { routePaths } from '../constants/routes'
import SEO from '../components/common/seo'
import { SubContainer } from '../components/common/Styled'
import { Field, Form } from 'react-final-form'
import PasswordField from '../components/common/PasswordField'
import { resetPassword } from '../helpers/api/user'
import { getParams } from '../helpers/urlParams'
import Header from '../components/common/Header'

const FormContainer = styled('form')`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 20px 100px;
  @media (max-width: 600px) {
    padding: 24px 16px;
    width: 100%;
    height: auto;
  }
`

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
  }
`

const PasswordPage = () => {
  const showSnackbar = useAppContext()?.showSnackbar
  const { home } = useStaticQuery(query)
  const code = getParams('code')?.code

  const handleSubmit = async values => {
    const { newPassword } = values
    try {
      await resetPassword({
        newPassword,
        code
      })
      showSnackbar('success', 'Mot de passe mit à jour')
      navigate(routePaths.login)
    } catch (e: any) {
      if (e.message === 'wrong-current-password')
        showSnackbar('error', 'Mot de passe incorrect')
      else showSnackbar('error', 'Une erreur est survenue, veuillez reessayer')
    }
  }

  const title = 'Réinitilisation du mot de passe'

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <LockReset />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <SEO title={title} />
      <Header {...{ items, title }} />
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          newPassword: '',
          passwordConfirmation: ''
        }}
        validate={values => {
          const errors: any = {}
          if (!values.newPassword) errors.newPassword = 'required'
          if (!values.passwordConfirmation)
            errors.passwordConfirmation = 'required'
          if (values.newPassword !== values.passwordConfirmation) {
            errors.passwordConfirmation = 'must-be-equal'
            errors.newPassword = 'must-be-equal'
          }
          return errors
        }}
        render={({ handleSubmit, errors, submitting }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Field
              name="newPassword"
              label="Nouveau mot de passe"
              render={PasswordField}
              helperText="Les mots de passe doivent être identiques"
            />
            <Field
              name="passwordConfirmation"
              label="Confirmation du mot de passe"
              render={PasswordField}
              helperText="Les mots de passe doivent être identiques"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEmpty(errors) || submitting}
            >
              Mettre à jour
            </Button>
          </FormContainer>
        )}
      />
    </SubContainer>
  )
}

export default PasswordPage
