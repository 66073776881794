import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";

import React, { useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { StyledTextField } from "./Styled";

type Props = FieldRenderProps<string>;

const PasswordField = (props: Props) => {
  const { input, label, helperText, required = true, meta, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <StyledTextField
      {...{ ...input, label, required, ...rest }}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error && helperText}
      color="primary"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? (
                <Visibility color="secondary" />
              ) : (
                <VisibilityOff color="secondary" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
